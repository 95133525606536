import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import PayLayout from './layouts/PayLayout';
import { copiriBankingPortalRequest, checkSiteUrlAccess } from './components/api/api'; // eslint-disable-next-line
import config from './components/config/config';
import CopiriLoader from './components/common/CopiriLoader'; // eslint-disable-next-line
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { CloseButton, Fade } from './components/common/Toast';
import { toast, ToastContainer } from 'react-toastify';
const App = () => {
	const [showHideLoader, setShowHideDueLoader] = useState(true);
	const [siteType, setSiteType] = useState('');
	useEffect(() => {
		getPublicAccessData();
		//getConfigDetailVariables();
		// eslint-disable-next-line
	}, []);

	function getPublicAccessData() {
		var language_id = localStorage.getItem('shibboleth');
		if (!language_id) {
			language_id = 177394503;
		}
		var url = '/languages/' + language_id + '/package/PRT/PUB';
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData === null || checkData === undefined || checkData === '') {
			copiriBankingPortalRequest(url, config.app_version, 'get')
				.then(response => {
					localStorage.setItem('idiom', JSON.stringify(response.data.result));
					getConfigDetailVariables(response.data.result);
				})
			.catch(error => {});
		} else {
			getConfigDetailVariables(checkData);
		}
	}

	function getConfigDetailVariables(publicAccessData) {
		setSiteType('');
		var site = checkSiteUrlAccess();
		var host_name = window.location.hostname;
		if (site && site === 'payments') {
			copiriBankingPortalRequest('/ahepa/settings/' + host_name, config.app_version, 'get')
				.then(response => {
					if (response && response.data) {
						if (response?.data?.site_icon) {
							/*site icon */
							let link = document.querySelector("link[rel~='icon']");
							if (!link) {
								link = document.createElement('link');
								link.rel = 'icon';
								document.getElementsByTagName('head')[0].appendChild(link);
							}
							link.href = response.data.site_icon;
						}
						localStorage.setItem('site_config_variables', JSON.stringify(response.data));
						localStorage.setItem('site_type', response.data.site);
						if (response.data.site === 'payments') {
							document.title = response.data.title;
						} else if (response.data.site === 'banking') {
							document.title = publicAccessData?.PUB?.PUB004;
						} else {
							document.title = publicAccessData?.PUB?.PUB005;
						}
						setTimeout(() => {
							setShowHideDueLoader(false);
						}, 1000);

						setSiteType(response.data.site);
					}
				})
				.catch(error => {
					setShowHideDueLoader(true);
					setTimeout(() => window.location.reload(), 1000);
				});
		} else {
			setShowHideDueLoader(false);
			setSiteType(site);
			if (site === 'banking') {
				document.title = publicAccessData?.PUB?.PUB004;
			} else {
				document.title = publicAccessData?.PUB?.PUB005;
			}
		}
	}

	return (
		<Router basename={process.env.PUBLIC_URL}>
			{showHideLoader ? (
				<CopiriLoader />
			) : (
				<>{siteType && siteType === 'payments' ? <PayLayout /> : <Layout siteType={siteType} />}</>
			)}
			<ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
		</Router>
	);
};

export default App;
