/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
import default_org_img from '../../assets/img/logos/no_gl_seal_light.png'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
function TreeItem({ item, funcs, nChild }) {
	// eslint-disable-next-line
	const { getStripeLink, checkLoader, inValidateLink, pageLanguageContentData } = funcs;
	return (
		<div key={item.org_id} className={nChild ? 'childClass' : 'parentClass'}>
			<div className={'d-flex align-items-center media px-1'}>
				<div className='left-avtar-content'>
					<div className='avatar-xl'>
						{item.seal ? (
							<img className='rounded-circle avatar-xl' src={item.seal} width='40' height='40' alt='loading error' />
						) : (
							<img
								className='rounded-circle avatar-xl'
								src={default_org_img}
								width='40'
								height='40'
								alt='loading error'
							/>
						)}
					</div>
					<div className='media-body'>
						<h5 className='fs--1'>
							<span>{item.org_name}</span>
						</h5>
					</div>
				</div>
				{item.seller_id && (
					<span className='manageAcc' style={{ color: '#337ab7',whiteSpace:'nowrap'  }}>
						{checkLoader && checkLoader[item.seller_id] ? (
							<span>
								{pageLanguageContentData['HME003'] ? pageLanguageContentData['HME003'] : ''}
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
									id={''}
									style={{ marginLeft: '5px' }}
								/>
							</span>
						) : (
							<>
								{inValidateLink ? (
									<span>
										{pageLanguageContentData['HME002'] ? pageLanguageContentData['HME002'] : ''}{' '}
										<i className='fas fa-check-square-right mr-0' style={{ marginLeft: '5px', fontSize: '14px  ' }}></i>
									</span>
								) : (
									<span style={{ cursor: 'pointer' }} onClick={() => getStripeLink(item.seller_id)}>
										{pageLanguageContentData['HME002'] ? pageLanguageContentData['HME002'] : ''}{' '}
										<i className='fas fa-check-square-right mr-0' style={{ marginLeft: '5px', fontSize: '14px  ' }}></i>
									</span>
								)}
							</>
						)}
					</span>
				)}
			</div>
			{item.children && <TreeList item={item} tree={item.children} funcs={funcs} key={item.id} nChild={true} />}
		</div>
	);
}

function TreeList({ item, tree, funcs, nChild = false }) {
	return (
		<>
			{tree.map((child, index) => (
				<TreeItem item={child} funcs={funcs} key={index} nChild={nChild} />
			))}
		</>
	);
}

const StripeAccountTree = ({
	data,
	pageLanguageContent,
	paymentData,
	getStripeLink,
	checkLoader,
	inValidateLink,
	pageLanguageContentData
}) => {
	// eslint-disable-next-line
	const [tree, setTree] = useState(paymentData);
	const funcs = {
		getStripeLink,
		checkLoader,
		inValidateLink,
		pageLanguageContentData
	};
	return <TreeList tree={tree} funcs={funcs} />;
};
export default StripeAccountTree;
