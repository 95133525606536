	// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import {  copiriAxiosPost } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
// eslint-disable-next-line
import {
	Card,
	CardHeader,
	CardBody,
	Media,
	NavLink,
	Table
} from 'reactstrap'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import seal_cmi from '../../assets/img/generic/no_gl_seal_light.png'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import TransactioDetailModal from '../admin/Payments/TransactioDetailModal';
import { Link } from 'react-router-dom';
const RecentPayments = props => {
	const { paymentLanguageContent } = props;
	const [paymentData, setPaymentData] = useState([]);// eslint-disable-next-line
	const [showLoader, setShowLoader] = useState(true);
	const [transcId,setTranscId] = useState('');
	const [showHideTransactionModal,setShowHideTransactionModal] = useState(false);
	// eslint-disable-next-line	
	useEffect(() => {
		// call functon to get the individual payments.
		getPayments();
		// eslint-disable-next-line
	}, []);
	/* function to call api to get the individuals payments*/
	function getPayments() {
		const formdata = new FormData();
		formdata.append('action', 'recent_payments');
		setShowLoader(true);
		copiriAxiosPost(config.portal_url, formdata)
		.then(response => {			
			setShowLoader(false);
			if (response.data && response.data.length>0) {
				setPaymentData(response.data);
			}
		})
		.catch(error => {setShowLoader(false);});
	}
	/* function to show the transaction detail modal */
	function transactiondetails(transc_id) {
		setShowHideTransactionModal(true);
		setTranscId(transc_id);
	}

	/* function to hide the transaction detail modal */
	function hideTransactionModal() {
		setShowHideTransactionModal(false);
	}
	return (
		<Card className='h-100 mt-3 mt-lg-0'>
			<CardHeader className='d-flex justify-content-between'>
			<Media className='align-items-center'>
					<img src={seal_cmi} alt='img1' width={70} />
					<Media body>
						<h4 className='fs-0 mb-0' style={{ marginLeft: '10px' }}>
							{paymentLanguageContent['PMT021'] ? paymentLanguageContent['PMT021'] : ''}
						</h4>	
						<h4 className='text-400  fs-0 mt-1' style={{ marginLeft: '10px' }}>
							{paymentLanguageContent['PMT022'] ? paymentLanguageContent['PMT022'] : ''}
						</h4>						
					</Media>
				</Media>
			</CardHeader>
			<CardBody className='fs--1'>
				<div className='p-0 card-body'>
					<Table striped className='table table-dashboard table-striped table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap table-members'>
				<thead>
					<tr className='bg-200 text-900'>
						<th className='text-left pl-2'><span style={{ cursor: 'pointer' }}>{paymentLanguageContent['PMT004'] ? paymentLanguageContent['PMT004'] : ''}</span></th>
						<th className='text-left pl-2'><span style={{ cursor: 'pointer' }}>{paymentLanguageContent['PMT005'] ? paymentLanguageContent['PMT005'] : ''}</span></th>
						<th className='text-left pl-2'><span style={{ cursor: 'pointer' }}>{paymentLanguageContent['PMT006'] ? paymentLanguageContent['PMT006'] : ''}</span></th>
						<th className='text-center pl-2'><span style={{ cursor: 'pointer' }}>{paymentLanguageContent['PMT008'] ? paymentLanguageContent['PMT008'] : ''}</span></th>
					</tr>
				</thead>
				<tbody>
					{showLoader ? (
						<tr>
							<td colSpan='4' align='center'>
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
								/>
							</td>
						</tr>
					) :(
						<>
							{ 	paymentData && paymentData.length > 0 &&
								paymentData.map((item, i) => (
									<tr onClick={() => transactiondetails(item.id)} style={{cursor:'pointer'}} key={i}>
										<td>{item.date?item.date:''}</td>
										<td>{item.payee?item.payee:''}</td>
										<td>{item.payor?item.payor:''}</td>
										<td className='text-right'>$ {item.amount?item.amount:''} &nbsp;<i  style={{color: '#337AB7',marginLeft:'5px'}} className="fas fa-question-circle"></i></td>
									</tr>
								))
							}
							{ 	paymentData && paymentData.length >= 0 && 
								<tr>
									<td colSpan='4' align='right'>
										
										<NavLink tag={Link} to='/payments' style={{ padding: '0' }}>
											<strong>
												<span id='button-text'>
													{paymentLanguageContent['PMT023'] ? paymentLanguageContent['PMT023'] : ''}
												</span>
											</strong>
										</NavLink>

									</td>
								</tr>
							}
						</>
					)}
					{
						!showLoader && paymentData.length<=0 &&
						<tr>
							<td colSpan='4' align='center'><strong>{paymentLanguageContent['PMT007'] ? paymentLanguageContent['PMT007'] : ''}</strong></td>
						</tr>
					}
				</tbody>
			</Table>
			<TransactioDetailModal showHideTransactionModal={showHideTransactionModal} hideTransactionModal={hideTransactionModal} transcId={transcId} pageLanguageContent={paymentLanguageContent}/>
				</div>
			</CardBody>
		</Card>
	);
};
export default RecentPayments;
