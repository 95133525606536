import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import StripeAccountOnboard from '../components/dashboard/StripeAccountOnboard';

import loadable from '@loadable/component';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Layout = props => {
	const { siteType } = props;
	useEffect(() => {
		AuthBasicLayout.preload();
	}, [siteType]);

	return (
		<Router fallback={<span />}>
			{siteType && siteType !== '' && siteType === 'portal' && (
				<Switch>
					<Route path='/errors' component={ErrorLayout} />
					<Route path='/authentication/basic' component={AuthBasicLayout} />
					<Route component={DashboardLayout} siteType={siteType} />
				</Switch>
			)}
			{siteType && siteType !== '' && siteType === 'banking' && (
				<Switch>
					<Route path='/errors' component={ErrorLayout} />
					<Route path='/authentication/basic' component={AuthBasicLayout} />
					<Route path='/' component={StripeAccountOnboard} />
				</Switch>
			)}
			<ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
		</Router>
	);
};

export default Layout;
